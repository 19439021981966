import React from 'react';
import { Link } from 'gatsby';

const Hashtags = ({ hashtag }) => (
  <div className="tags">
    {hashtag.split(',').map((tag, index) => (
      <Link key={index} to={`/search?query=${tag.trim()}`} state={{ searchQuery: tag.trim() }}>
        <div>#{tag.trim()}</div>
      </Link>
    ))}
  </div>
);

export default Hashtags;
