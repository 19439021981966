import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

import Hashtags from '../components/Hashtags';

import IconFB from '../components/Icons/facebook';
import IconTwitter from '../components/Icons/twitter';
import IconMailSm from '../components/Icons/mail-sm';
import {
  IconVideo,
  IconText,
  IconAudio,
  IconVeranstaltung,
  IconSchulung
} from '../components/Icons';

import { BVOG_URL } from '../constants';
import { getDateAndTime } from '../utils/common';

const PostEvent = ({ node, isSearchPage = false }) => (
  <article className="article" id={node.link}>
    <div className="container">
      <div className="post-page__wrapper">
        <div className="post-page__social">
          <div>
            <FacebookShareButton url={`${BVOG_URL}/veranstaltungen/${node.link}`}>
              <IconFB />
            </FacebookShareButton>
          </div>
          <div>
            <TwitterShareButton url={`${BVOG_URL}/veranstaltungen/${node.link}`}>
              <IconTwitter />
            </TwitterShareButton>
          </div>
          <div>
            <EmailShareButton url={`${BVOG_URL}/veranstaltungen/${node.link}`}>
              <IconMailSm />
            </EmailShareButton>
          </div>
        </div>
        <div className="post-page__content">
          <div className="post-page__content__wrapper">
            {node.image && (
              <div className="post-page__content__left">
                <div className="post-page__content__image">
                  <Img
                    fluid={node.image.fluid}
                    alt={node.beitragsname}
                    style={{ width: 250, height: 250 }}
                  />
                  <div className="post-page__content__image__top">
                    <div className="content-hashtags">
                      {node.themenschwerpunkt.map((tag, index) => (
                        <span key={index}>#{tag.link}</span>
                      ))}
                    </div>

                    <div className="content-icons">
                      {node.contentModules.some(
                        content => content.__typename === 'DatoCmsModText'
                      ) && <IconText />}
                      {node.art === 'Schulung' ? <IconSchulung /> : <IconVeranstaltung />}
                      {node.contentModules.some(
                        content => content.__typename === 'DatoCmsModVideo'
                      ) && <IconVideo />}
                      {node.contentModules.some(
                        content => content.__typename === 'DatoCmsModPodcast'
                      ) && <IconAudio />}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="post-page__content__right">
              <Link to={`/veranstaltungen/${node.link}`}>
                <h3 className="post-page__content__right__date">{getDateAndTime(node.beginn)}</h3>
                <h3 className="post-page__content__right__location">{node.ort}</h3>
                <h3 className="post-page__content__right__title">{node.beitragsname}</h3>

                <div dangerouslySetInnerHTML={{ __html: node.frontText }} />
              </Link>

              {node.hashtag && !isSearchPage && <Hashtags hashtag={node.hashtag} />}
            </div>
          </div>

          {node.hashtag && isSearchPage && <Hashtags hashtag={node.hashtag} />}
        </div>
      </div>
    </div>
  </article>
);

export default PostEvent;
